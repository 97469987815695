.custom-collapse > .ant-collapse-item {
    border-bottom: 1px solid rgb(89, 236, 187);
    max-width:900px;

  }

  .custom-collapse .ant-collapse-header {
    font-size: 18px;
    font-weight: 600;
    max-width:auto;
  }
  
  .custom-collapse .ant-collapse-content p {
    font-size: 15px;
    color: #323232;
    font-weight: 500;
    max-width: 900px;
  }
  
  /* Add styles for mobile screen with max-width 400px */
  @media (max-width: 400px) {
    .custom-collapse .ant-collapse-header {
      font-size: 16px;
      max-width: 360px;

    }
  
    .custom-collapse .ant-collapse-content p {
      font-size: 14px;
      max-width: 100%;
    }
  
    .custom-collapse {
      padding: 10px;
    }
  }


  @media (max-width: 576px) {
    .ant-tabs-nav-list {
      flex-wrap: wrap !important;
    }
    
    .ant-tabs-tab {
      flex: 1 1 100%;
      text-align: center;
    }
  
    .tab-content {
      padding: 20px !important;
      font-size: 18px !important;
    }
  }

  /* Additional styles for left tab position */
/* .ant-tabs-left > .ant-tabs-nav {
  width: 220px;
} */

.ant-tabs-left > .ant-tabs-content-holder {
  margin-left: 0px;
}

.ant-tabs-left .ant-tabs-tab {
  text-align: left;
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border-right: 2px solid #e6e6e6;
}

.ant-tabs-left .ant-tabs-tab-active {
  border-right-color: #1890ff;
}

.ant-tabs-left .ant-tabs-tab:hover {
  color: #40a9ff;
}
  
@media (max-width: 576px) {
  .ant-tabs-left {
    flex-direction: column;
  }

  /* .ant-tabs-left > .ant-tabs-nav {
    width: 100%;
    overflow-x: auto;
  } */

  
}
  
  